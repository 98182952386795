
































.school-admin {
    height: 100%;
}
